<template>
  <div class="grid">
    <div class="col-12 md:col-12">
      <Toast/>
        <Toolbar class="mb-4">
          <template v-slot:start>
            <Button :label="$t('button.save')" icon="pi pi-save" class="p-button mr-2" @click="onSave" />
            <!-- <Button :label="$t('button.print')" icon="pi pi-print" class="p-button mr-2" @click="onPreviewShipping" v-if="shipment.id" /> -->
            <Button :label="$t('button.info')" icon="pi pi-book" class="p-button mr-2" @click="onEditInfo" /> 
            <Button :label="$t('button.scanner')" icon="pi pi-search" class="p-button mr-2" @click="openScanner" v-if="shipment.id"/>              
            <!-- <Button :label="$t('button.export')" icon="pi pi-file-export" class="p-button" @click="onExportManifest" v-if="shipment.id"/> -->
          </template>
          <template #center>
            <div class="my-2">
              <h3>{{ shippingInfo }}</h3>
            </div>             
          </template>
          <template v-slot:end>  
            <Button :label="$t('button.qrcode')" icon="pi pi-qrcode" class="p-button mr-2" @click="showShipmentURL" v-if="shipment.id"/>          
            <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />
            <Button label="Export" icon="pi pi-upload" class="p-button-success" @click="exportCSV($event)"  />
            <Button :label="$t('button.delete')" icon="pi pi-trash" class="p-button-danger" @click="onDelete" /> -->
          </template>
        </Toolbar>
        <div class="flex">
          <div class="flex flex-grow-1 flex-shrink-1">
            <!--Add filter handler for lazy dataTable-->
            <DataTable ref="source" style="width: 100%;"
                dataKey="id" 
                filterDisplay="menu"
                :value="allSourcePackages"  
                :showGridlines=true                
                v-model:filters="sourceFilters"
                :paginator="true" 
                :totalRecords="totalSourcePackages"
                :rows="pageSize"
                paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                :rowsPerPageOptions="rowsPerPageOptions"
                currentPageReportTemplate='Showing {first} to {last} of {totalRecords} packages'
                v-model:selection="selectedSourcePackages"                 
                responsiveLayout="scroll"
                :lazy="true"
                @page="onSourcePaging"
                @filter="onSourceFilter">
              <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                  <h5 class="m-0">{{$t('shipment.selected_counter', {selected: selectedSourcePackages.length, all: allSourcePackages.length})}}</h5>
                  <h5 class="m-0">{{$t('shipment.selected_weight', {selectedLbs: selectedSourceWeight, selectedKg: tokg(selectedSourceWeight), allLbs: totalSourceWeight, allKg: tokg(totalSourceWeight)})}}</h5>
                  <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                      <i class="pi pi-search" />
                      <InputText v-model="sourceFilters['global'].value" placeholder="Search..." />
                  </span> -->
                </div>
              </template>
              <Column selectionMode="multiple" headerStyle="width:10%; min-width: 5rem" v-if="shipment.status=='inited'"></Column>
              <Column field="createdDate" filterField="createdDate"
                :header="$t('package.createdDate')" 
                :sortable="true" 
                dataType="date"
                headerStyle="width:30%; min-width:10rem;">
                <template #body="slotProps">
                  <span class="p-column-title">{{$t('package.createdDate')}}</span>
                  {{this.formatDate(slotProps.data.createdDate)}}
                </template>
                <template #filter="{filterModel}">
                  <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
                </template>
              </Column>
              <Column field="packageCode" 
                :header="$t('package.packageCode')" 
                :sortable="true" 
                headerStyle="width:30%; min-width:10rem;">
                <template #body="slotProps">
                  <span class="p-column-title">{{$t('package.packageCode')}}</span>
                  {{slotProps.data.packageCode}}
                </template>
                <template #filter="{filterModel}">
                  <InputText 
                    v-model="filterModel.value" 
                    class="p-column-filter" 
                    :placeholder="$t('common.search_by_field',[$t('package.packageCode')])"/>
                </template>
              </Column>
              <Column field="shipmentCode" :header="$t('package.shipmentCode')" :sortable="true" headerStyle="width:30%; min-width:10rem;">
                <template #body="slotProps">
                  <span class="p-column-title">{{$t('package.shipmentCode')}}</span>
                  {{slotProps.data.shipmentCode}}
                </template>
                <template #filter="{filterModel}">
                  <InputText v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('package.shipmentCode')])"/>
                </template>
              </Column>
              <Column field="weightLbs" :header="$t('package.weightLbs') + '/' + $t('package.weightKg')" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                <template #body="slotProps">
                  <span class="p-column-title">{{$t('package.weightLbs')}}</span>
                  {{slotProps.data.weightLbs}} / {{tokg(slotProps.data.weightLbs)}}
                </template>
                <template #filter="{filterModel}">                  
                  <InputNumber v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('package.weightLbs')])"/>              
                </template>
              </Column>
              <!-- <Column field="items" :header="$t('package.items')" :sortable="true" headerStyle="width:14%; min-width:20rem;">
                <template #body="slotProps">
                  <span class="p-column-title">{{$t('package.items')}}</span>
                  {{slotProps.data.items}}
                </template>
              </Column> -->
            </DataTable>
          </div>
          <div class="flex flex-shrink-0 p-picklist">
            <div class="flex flex-column p-3" data-pc-section="buttons">
              <Button icon="pi pi-angle-right" class="p-button mb-2" 
                :disabled="selectedSourcePackages.length == 0 || shipment.status!='inited'"
                @click="onMoveToTarget"/>
              <Button icon="pi pi-angle-double-right" class="p-button mb-2" 
                :disabled="shipment.status!='inited'"
                @click="onMoveAllToTarget"/>
              <Button icon="pi pi-angle-left" class="p-button mb-2" 
                :disabled="selectedTargetPackages.length == 0 || shipment.status!='inited'" 
                @click="onMoveToSource"/>
              <Button icon="pi pi-angle-double-left" class="p-button mb-2" 
                :disabled="shipment.status!='inited'"
                @click="onMoveAllToSource"/>
            </div>
          </div>
          <div class="flex flex-grow-1 flex-shrink-1">
            <DataTable ref="target" style="width: 100%;"
                dataKey="id"
                filterDisplay="menu"
                :value="allTargetPackages" 
                v-model:selection="selectedTargetPackages"   
                :showGridlines=true
                v-model:filters="targetFilters"
                :paginator="false" 
                :rows="pageSize"
                :totalRecords="totalTargetPackages"
                :rowsPerPageOptions="rowsPerPageOptions"
                currentPageReportTemplate='Showing {first} to {last} of {totalRecords} packages'
                responsiveLayout="scroll">

              <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                  <h5 class="m-0">{{$t('shipment.selected_counter', {selected: selectedTargetPackages.length, all: allTargetPackages.length})}}</h5>
                  <h5 class="m-0">{{$t('shipment.selected_weight', {selectedLbs: selectedTargetWeight, selectedKg: tokg(selectedTargetWeight), allLbs: totalTargetWeight, allKg: tokg(totalTargetWeight)})}}</h5>
                  <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                      <i class="pi pi-search" />
                      <InputText v-model="targetFilters['global'].value" placeholder="Search..." />
                  </span> -->
                </div>
              </template>

              <Column selectionMode="multiple" headerStyle="width: 10%; min-width:5rem" v-if="shipment.status=='inited'"></Column>
              <Column field="createdDate" :header="$t('package.createdDate')" :sortable="true" dataType="date" headerStyle="width:30%; min-width:10rem;">
                <template #body="slotProps">
                  <span class="p-column-title">{{$t('package.createdDate')}}</span>
                  {{this.formatDate(slotProps.data.createdDate)}}
                </template>
                <template #filter="{filterModel}">
                  <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />                  
                </template>
              </Column>
              <Column field="packageCode" 
                :header="$t('package.packageCode')" 
                :sortable="true" 
                headerStyle="width:30%; min-width:10rem;">
                <template #body="slotProps">
                  <span class="p-column-title">{{$t('package.packageCode')}}</span>
                  {{slotProps.data.packageCode}}
                </template>
                <template #filter="{filterModel}">
                  <InputText v-model="filterModel.value" 
                  class="p-column-filter" 
                  :placeholder="$t('common.search_by_field',[$t('package.packageCode')])"/>
                </template>
              </Column>
              <Column field="shipmentCode" :header="$t('package.shipmentCode')" :sortable="true" headerStyle="width:30%; min-width:10rem;">
                <template #body="slotProps">
                  <span class="p-column-title">{{$t('package.shipmentCode')}}</span>
                  {{slotProps.data.shipmentCode}}
                </template>
                <template #filter="{filterModel}">
                  <InputText v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('package.shipmentCode')])"/>
                </template>
              </Column>
              <Column field="weightLbs" :header="$t('package.weightLbs') + '/' + $t('package.weightKg')" 
                :sortable="true" :filter= "true" 
                headerStyle="width:30%; min-width:10rem;">
                <template #body="slotProps">
                  <span class="p-column-title">{{$t('package.weightLbs')}} / {{$t('package.weightKg')}}</span>
                  {{slotProps.data.weightLbs}} / {{tokg(slotProps.data.weightLbs)}}
                </template>
                <template #filter="{filterModel}">                  
                  <InputNumber v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('package.weightLbs')])"/>              
                </template>
              </Column>
              <!-- <Column field="items" :header="$t('package.items')" :sortable="true" headerStyle="width:14%; min-width:20rem;">
                <template #body="slotProps">
                  <span class="p-column-title">{{$t('package.items')}}</span>
                  {{slotProps.data.items}}
                </template>
              </Column> -->
            </DataTable>
          </div>
        </div>
    </div>    
    
    <Dialog v-model:visible="showProgressSpinner" modal :closable="false" :style="{ width: '50vw' }">
      <ProgressSpinner/>
    </Dialog>
    <DialogExportForm :entity="shipment" :visible="showDialogInfo"
					@hide="showDialogInfo=false"
					@cancel="showDialogInfo=false"
					@save="saveEntityInfo($event)"/>
    <DialogExportQRCode :shipmentId="shipment.id" v-model:visible="showDialogQRCode"
					@hide="showDialogQRCode=false"
					@cancel="showDialogQRCode=false"/>
  </div>
</template>
<script>
import { FilterService, FilterOperator, FilterMatchMode } from "primevue/api";
import DialogExportForm from "./DialogExportForm.vue";
import DialogExportQRCode from "./DialogExportQRCode.vue";
// import PickList from "@/components/PickList";
// import PackageItem from "@/components/PackageItem";
// import AutoCompleteValue from "@/components/form/AutoCompleteValue";
import ShipmentService from "@/service/ShipmentService";
import PackageService from "@/service/PackageService";
import formatter from "@/utils/formatter";
import adjustment from "@/utils/adjustment";
import { converter, getCodeString } from "@/utils/common";
import Consts from '@/utils/consts';
import { writeFile, utils } from "xlsx";
import filter from '@/mixins/filter'
export default {
  components: { DialogExportForm, DialogExportQRCode },
  mixins: [filter],
  data() {
    return {
      shipment: {
        status: "inited"
      },
      showDialogInfo: false,
      showDialogQRCode: false,
      showProgressSpinner:false,
      unshippedPackages: [],
      allSourcePackages: [],
      allTargetPackages: [],
      selectedSourcePackages: [],
      selectedTargetPackages: [],
      unselectedShipmentPackages: [],   //Package removing from current shipment
      sourceFilters: {
        packageCode: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
      targetFilters: {
        packageCode: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
      filterOps: [
        { name: "Contains" },
        { name: "Greater than" },
        { name: "Less than" },
        { name: "Between" },
      ],
      pageSize: Consts.DEFAULT_PAGE_ROWS,
      rowsPerPageOptions: Consts.ROW_PER_PAGE_OPTIONS,
      totalSourcePackages: 0,
      totalTargetPackages: 0,
      currentSourcePage: 0,
      currentSourceRows: Consts.DEFAULT_PAGE_ROWS,
      formatter,
      enableTimeout: true,
      timeout: 5000,
      timeoutUnshippedPackages: null,
    };
  },
  computed: {
    totalSourceWeight() {
      return this.allSourcePackages.reduce((total, item) => total + item.weightLbs, 0);
    },
    selectedSourceWeight() {
      return this.selectedSourcePackages.reduce((total, item) => total + item.weightLbs, 0);
    },
    totalTargetWeight() {
      return this.allTargetPackages.reduce((total, item) => total + item.weightLbs, 0);
    },
    selectedTargetWeight() {
      return this.selectedTargetPackages.reduce((total, item) => total + item.weightLbs, 0);
    },
    shippingInfo() {
      let info = this.shipment.shipmentCode;
      if (this.shipment.awbCode) {
        info += `- ${this.shipment.awbCode}`;
      }
      if (this.shipment.departure) {
        let departure = this.formatDate(this.shipment.departure);
        info += `- ${departure}`;
      }
      return info;
    },
    sourceReceipts() {
      let receipts = [];
      if (Array.isArray(this.allSourcePackages)) {
        for (let i = 0; i < this.allSourcePackages.length; i++) {
          let receiptCode = this.allSourcePackages[i].receiptCode;
          if (receipts.indexOf(receiptCode) < 0) {
            receipts.push(receiptCode);
          }
        }
      }
      return receipts;
    },
    targetReceipts() {
      let receipts = [];
      if (Array.isArray(this.allTargetPackages)) {
        for (let i = 0; i < this.allTargetPackages.length; i++) {
          let receiptCode = this.allTargetPackages[i].receiptCode;
          if (receipts.indexOf(receiptCode) < 0) {
            receipts.push(receiptCode);
          }
        }
      }
      return receipts;
    },
  },
  created() {
    this.initGlobalFilter(this.sourceFilters);
    this.initGlobalFilter(this.targetFilters);
	},
  mounted() {
    this.initColumnFilters(this.$refs.source, this.sourceFilters);
    this.initColumnFilters(this.$refs.target, this.targetFilters);    
    this.loadUnshippedPackages();
    this.loadShipment();
  },
  // beforeUnmount() {
  //   if (this.timerRefreshList) {
  //     clearTimeout(this.timerRefreshList);
  //   }
  //   this.timerRefreshList = null;
  // },
  methods: {
    loadShipment() {
      if (this.$route.params.id) {
        ShipmentService.get(this.$route.params.id).then((response) => {
          this.shipment = adjustment.adjustShipment(response);
          // let mapPackages = {};
          // if (Array.isArray(this.shipment.receipts)) {
          //   this.shipment.receipts.forEach(receipt => {
          //     if (Array.isArray(receipt.packageEntities)) {
          //       receipt.packageEntities.forEach(item => {mapPackages[item.id] = item});
          //     }
          //   });
          // }
          this.allTargetPackages = this.shipment.packages.map(item=>item);
          setTimeout(this.loadShipment, this.timeout);
        });
      }
    },
    loadUnshippedPackages() {
      let targetUnshippedPkgs = this.allTargetPackages.filter(item=>item.shipmentCode == null);
      let options = {
        filters: {},
        page: this.currentSourcePage,
        size: this.currentSourceRows,
        offset: targetUnshippedPkgs.length
      };
      for(var field in this.sourceFilters) {
        if (field == 'global') continue;
        let notNullConstraints = this.sourceFilters[field].constraints.filter(item=> item.value != null);
        if (notNullConstraints.length > 0) {
          options.filters[field] = {
            constraints: notNullConstraints,
            operator:this.sourceFilters[field].operator
          };
        }
      }
      if (this.timeoutUnshippedPackages) {
        clearTimeout(this.timeoutUnshippedPackages);
      }
      PackageService.getUnshipped(options).then((response) => {
        this.unshippedPackages = response.content || [];
        this.allSourcePackages = this.unselectedShipmentPackages.concat(response.content || []);
        this.totalSourcePackages = response.totalElements - this.allTargetPackages.length;
        this.timeoutUnshippedPackages = setTimeout(this.loadUnshippedPackages, this.timeout);
      });
    },
    onSourcePaging(event) {
      this.currentSourcePage = event.page;
      this.currentSourceRows = event.rows;
      this.loadUnshippedPackages();
    },
    onSourceFilter() {
      this.loadUnshippedPackages();
    },
    tokg(lbs) {
      return converter.tokg(lbs);
    },
    formatDate(jsDate) {
			return formatter.formatDate(jsDate, Consts.FORMAT_DATE_US);
		},
    onChangeSelection() {},
    _calculateWeights() {
      this.shipment.weightLbs = this.allTargetPackages.reduce(
        (prev, cur) => prev + cur.weightLbs,
        0
      );
      this.shipment.weightKg = converter.tokg(this.shipment.weightLbs);
    },
    onSave() {
      this.shipment.packageIds = this.allTargetPackages.map((pkg) => pkg.id);
      if (this.shipment.id) {
        this.showProgressSpinner = true;
        ShipmentService.update(this.shipment.id, this.shipment).then(
          (response) => {
            this.shipment = adjustment.adjustShipment(response);
            this.allSourcePackages.forEach(item=>{item.shipmentCode = null;});
            this.allTargetPackages.forEach(item=>{item.shipmentCode = this.shipment.shipmentCode;});
            this.showProgressSpinner = false;
            this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t("shipment.update_packages_successfully"), life: 3000});
          }
        ).catch((e) => {
            console.log(e);
            this.showProgressSpinner = false;
          });
      } else {
        this.showProgressSpinner = true;
        ShipmentService.create(this.shipment)
          .then((response) => {
            this.shipment = adjustment.adjustShipment(response);
            this.showProgressSpinner = false;
          })
          .catch((e) => {
            console.log(e);
            this.showProgressSpinner = false;
          });
      }
    },
    onPreviewShipping() {
      const routeData = this.$router.resolve({ name: 'print_preview', params: {resource: "shipment", id: this.shipment.id} });
      window.open(routeData.href);
    },
    onEditInfo() {
      this.showDialogInfo = true;
    },
    openScanner() {
      const routeData = this.$router.resolve({ name: 'agent_package_scanner', params: {id: this.shipment.id} });
      window.open(routeData.href);
    },
    showShipmentURL() {
      this.showDialogQRCode = true;
    },
    saveEntityInfo(info) {
      for (let field in info){
        this.shipment[field] = info[field];
      }
      if (this.shipment.id) {
        let data = {
          id: this.shipment.id
        };
        if (this.shipment.status == "inited") {
          ["note","departure","awbCode","weightLbs"].forEach(field => {
            data[field] = this.shipment[field];
          });
        } else {
          data.note = this.shipment.note;
        }
        ShipmentService.update(this.shipment.id, data).then(
          (response) => {
            this.shipment = adjustment.adjustShipment(response);
            this.showDialogInfo = false;
          }
        );
      } else {
        ShipmentService.create(this.shipment)
          .then((response) => {
            this.shipment = adjustment.adjustShipment(response);
            this.showDialogInfo = false;
          })
          .catch((e) => console.log(e));
      }
      
    },
    onFilterSource() {
      let filteredSourcePackages = this.sourceFilter
        ? FilterService.filter(
            this.allSourcePackages,
            ["packageCode"],
            this.sourceFilter,
            FilterMatchMode.CONTAINS
          )
        : [...this.allSourcePackages];
      this.allPackages = [filteredSourcePackages, this.allPackages[1]];
    },
    onFilterTarget() {
      let filteredTargetPackages = this.targetFilter
        ? FilterService.filter(
            this.allTargetPackages,
            ["packageCode"],
            this.targetFilter,
            FilterMatchMode.CONTAINS
          )
        : [...this.allTargetPackages];
      this.allPackages = [this.allPackages[0], filteredTargetPackages];
    },
    onSourceFilterClick() {
      console.log("onSourceFilter");
      let filteredPackages = this._doFilter(
        this.allSourcePackages,
        this.sourceFilter
      );
      this.allPackages[0] = filteredPackages;
    },
    onTargetFilterClick() {
      console.log("onTargetFilter");
      let filteredPackages = this._doFilter(
        this.allTargetPackages,
        this.targetFilter
      );
      this.allPackages[1] = filteredPackages;
    },
    _doFilter(aPackageList, filter) {
      let filteredPackages = [];
      if (filter.receiptCode) {
        filteredPackages = FilterService.filter(
          aPackageList,
          ["receiptCode"],
          filter.receiptCode,
          FilterMatchMode.EQUALS
        );
        let start, end;
        if (filter.first) {
          start = getCodeString(filter.first, 3, filter.receiptCode);
        }
        if (filter.last) {
          end = getCodeString(filter.last, 3, filter.receiptCode);
        }
        if (start && end) {
          filteredPackages = FilterService.filter(
            filteredPackages,
            ["packageCode"],
            [start, end],
            FilterMatchMode.BETWEEN
          );
        } else if (start) {
          filteredPackages = FilterService.filter(
            filteredPackages,
            ["packageCode"],
            start,
            FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
          );
        } else if (end) {
          filteredPackages = FilterService.filter(
            filteredPackages,
            ["packageCode"],
            end,
            FilterMatchMode.LESS_THAN_OR_EQUAL_TO
          );
        }
      } else {
        filteredPackages = aPackageList;
      }
      return filteredPackages;
    },
    onMoveToTarget() {
      this._moveFromSourceToTarget(this.selectedSourcePackages);
    },
    onMoveAllToTarget() {
      this._moveFromSourceToTarget(this.allSourcePackages);
    },
    onMoveToSource() {
      this._moveFromTargetToSource(this.selectedTargetPackages);
    },
    onMoveAllToSource() {
      this._moveFromTargetToSource(this.allTargetPackages);
    },
    _moveFromSourceToTarget(items) {
      this.allTargetPackages.push(...items);
      this.allSourcePackages = this.allSourcePackages.filter(
        (item) => !items.includes(item)
      );      
      this.selectedSourcePackages = [];
      let itemIds = {};
      items.forEach(item => {
        if (item.shipmentCode) {
          itemIds[item.id] = true;
        }        
      });
      let newArrs = [];
      this.unselectedShipmentPackages.forEach(item => {
        if (itemIds[item.id] == undefined) {
          newArrs.push(item);
        }
      });
      this.unselectedShipmentPackages = newArrs;
      this._calculateWeights();
      this.loadUnshippedPackages();
    },
    _moveFromTargetToSource(items) {
      this.allTargetPackages = this.allTargetPackages.filter(
        (item) => !items.includes(item)
      );      
      this.selectedTargetPackages = [];
      items.forEach(item => {
        if (item.shipmentCode) {
          this.unselectedShipmentPackages.push(item);
          this.allSourcePackages.unshift(item);
        } else {
          this.allSourcePackages.push(item);
        }
      });
      this._calculateWeights();
    },
    onSourcePage(event) {
      console.log(event);
    },
    onTargetPage(event) {
      console.log(event);
    },
    onClickApply(event) {
      console.log(event);
    },
    onExportManifest() {
      let receipts = {};
      let selectedPackages = this.allPackages[1];
      selectedPackages = selectedPackages.sort(
        (a, b) => a.packageCode < b.packageCode
      );
      this.shipment.receipts.forEach((receipt) => {
        receipts[receipt.receiptCode] = receipt;
      });
      /*
      selectedPackages.forEach(pkg => {
        const receipt = this._addPackage(receipts[pkg.receiptCode], pkg)
        if (receipt) {
          receipts[pkg.receiptCode] = receipt
        }
      });
      */
      //Manifests
      const manifests = this._createManifests(selectedPackages, receipts);
      // Shipping form
      const shipingForm = this._createShippingForm(selectedPackages, receipts);

      var wb = utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      utils.book_append_sheet(wb, manifests, "Manifests"); // sheetAName is name of Worksheet
      utils.book_append_sheet(wb, shipingForm, "ShippingList"); // sheetAName is name of Worksheet
      // export Excel file
      const fileName = "shipment-" + this.shipment.shipmentCode + ".xlsx";
      writeFile(wb, fileName); // name of the file is 'book.xlsx'
    },
    _createManifests(selectedPackages, mapReceipts) {
      const manifests = selectedPackages.map((pkg, ind) => {
        const receipt = mapReceipts[pkg.receiptCode];
        const hdrPackageCode = this.$t("package.packageCode");
        const hdrWeightLbs = this.$t("shipment.weightLbs");
        const hdrWeightKg = this.$t("shipment.weightKg");
        const hdrSenderName = this.$t("shipment.senderName");
        const hdrSenderAddress = this.$t("shipment.senderAddress");
        const hdrSenderPhone = this.$t("shipment.senderPhone");
        const hdrRecipientName = this.$t("shipment.recipientName");
        const hdrRecipientAddress = this.$t("shipment.recipientAddress");
        const hdrRecipientPhone = this.$t("shipment.recipientPhone");
        const hdrDescription = this.$t("shipment.description");
        return {
          No: ind + 1,
          [hdrPackageCode]: pkg.packageCode,
          ["Pcs"]: 1,
          [hdrWeightLbs]: receipt.weightLbs,
          [hdrWeightKg]: converter.tokg(receipt.weightLbs),
          [hdrSenderName]: receipt.dSenderName || receipt.senderName,
          [hdrSenderAddress]: receipt.dSenderAddress || receipt.senderAddress,
          [hdrSenderPhone]: receipt.dSenderPhone || receipt.senderPhone,
          [hdrRecipientName]: receipt.dRecipientName || receipt.recipientName,
          [hdrRecipientAddress]:
            receipt.dRecipientAddress || receipt.recipientAddress,
          [hdrRecipientPhone]:
            receipt.dRecipientPhone || receipt.recipientPhone,
          
          ["Value"]: "GIFT",
          [hdrDescription]: pkg.packageItems
            .map((item) => item.quantity + " " + item.name)
            .join(", "),
        };
      });
      var sheet = utils.json_to_sheet(manifests, {
        origin: "A4",
      });
      sheet["!cols"] = [
        { wch: 6 },
        { wch: 12 },
        { wch: 18 },
        { wch: 30 },
        { wch: 10 },
        { wch: 18 },
        { wch: 30 },
        { wch: 10 },
        { wch: 6 },
        { wch: 6 },
        { wch: 6 },
        { wch: 30 },
      ];

      const totalWeight = selectedPackages.reduce(
        (total, pkg) => total + pkg.weightLbs,
        0
      );
      utils.sheet_add_aoa(sheet, [[this.shipment.shipmentCode]], {
        origin: "A1",
      });
      utils.sheet_add_aoa(sheet, [[this.shipment.awbCode]], {
        origin: "C1",
      });
      utils.sheet_add_aoa(
        sheet,
        [
          [
            this.$t("shipment.number_of_packages", {
              numPackages: selectedPackages.length,
            }),
          ],
        ],
        { origin: "A2" }
      );
      utils.sheet_add_aoa(
        sheet,
        [[this.$t("shipment.totalWeight", { weight: totalWeight })]],
        { origin: "D2" }
      );
      return sheet;
    },
    _createShippingForm(selectedPackages, mapReceipts) {
      const hdrIndex = this.$t("package.noOfPCs");
      const hdrReceiptNumber = this.$t("shipment.receiptNumber");
      const hdrPackingNumber = this.$t("shipment.packingNumber");
      const hdrShipmentNumber = this.$t("shipment.shipmentNumber");
      const hdrWeightLbs = this.$t("shipment.weightLbs");
      const hdrWeightKg = this.$t("shipment.weightKg");
      const hdrRecipientName = this.$t("shipment.recipientName");
      const hdrRecipientAddress = this.$t("shipment.recipientAddress");
      //const hdrRecipientDistrict = this.$t('shipment.recipientDistrict');
      //const hdrRecipientCity = this.$t('shipment.recipientCity');
      const hdrRecipientPhone = this.$t("shipment.recipientPhone");
      const hdrDescription = this.$t("shipment.description");
      const shippingForm = selectedPackages.map((pkg, ind) => {
        const receipt = mapReceipts[pkg.receiptCode];
        return {
          [hdrIndex]: ind + 1,
          [hdrReceiptNumber]: pkg.receiptCode,
          [hdrPackingNumber]: pkg.packageCode,
          [hdrShipmentNumber]: this.shipment.shipmentCode,
          [hdrWeightLbs]: pkg.weightLbs,
          [hdrWeightKg]: converter.tokg(pkg.weightLbs),
          [hdrRecipientName]: receipt.dRecipientName || receipt.recipientName,
          [hdrRecipientAddress]:
            receipt.dRecipientAddress ||
            [
              receipt.recipientAddress,
              receipt.receiptDistrict,
              receipt.receiptProvince,
            ].join(","),
          [hdrRecipientPhone]:
            receipt.dRecipientPhone || receipt.recipientPhone,
          [hdrDescription]: pkg.packageItems
            .map((item) => item.quantity + " " + item.name)
            .join(","),
        };
      });
      var sheet = utils.json_to_sheet(shippingForm, {
        origin: "A4",
      });
      sheet["!cols"] = [
        { wch: 6 },
        { wch: 10 },
        { wch: 12 },
        { wch: 8 },
        { wch: 6 },
        { wch: 6 },
        { wch: 18 },
        { wch: 30 },
        { wch: 10 },
        { wch: 30 },
      ];
      const totalWeight = selectedPackages.reduce(
        (total, pkg) => total + pkg.weightLbs,
        0
      );
      utils.sheet_add_aoa(sheet, [[this.$t("shipment.shippingFormTitle")]], {
        origin: "A1",
      });
      utils.sheet_add_aoa(
        sheet,
        [
          [
            this.$t("shipment.number_of_packages", {
              numPackages: selectedPackages.length,
            }),
          ],
        ],
        { origin: "A2" }
      );
      utils.sheet_add_aoa(
        sheet,
        [[this.$t("shipment.totalWeight", { weight: totalWeight })]],
        { origin: "D2" }
      );
      return sheet;
    },
    _addPackage(receipt, pkg) {
      if (!receipt && this.shipment && this.shipment.receipts) {
        for (let i = 0; i < this.shipment.receipts.length; i++) {
          if (this.shipment.receipts[i].receiptCode == pkg.receiptCode) {
            receipt = {
              ...this.shipment.receipts[i],
              packageNumber: 0,
              weightLbs: 0,
              content: "",
            };
            break;
          }
        }
      }
      if (receipt) {
        receipt.packageNumber += 1;
        receipt.weightLbs += pkg.weightLbs;
        if (!receipt.content) {
          receipt.content = this._getPackageContent(pkg);
        } else {
          receipt.content += "," + this._getPackageContent(pkg);
        }
      }
      return receipt;
    },
    //Todo:
    _getPackageContent(pkg) {
      return pkg.description;
    },
  },
};
</script>

<style lang="scss" scoped>
/*
.p-picklist-header .p-toolbar {
  padding: 0 1rem;
  margin: -0.5rem 0;
  border: 0;
  font-weight: 600;
}
*/
</style>
